<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import stateOption from "@/components/forms/state-option";
import InputGroup from "@/components/forms/input-group";
import axios from "axios";
import { authHeader } from "../../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import router from "../../../../router";
// import { loopDetails } from "@/util/mapfunction.js";

export default {
	components: {
		Layout,
		PageHeader,
		InputGroup,
		stateOption,
		MessageModal
	},
	data() {
		return {
			id: this.$route.params.id,
			action: "",
			title: "Member's Address",
			items: [],
			updateHeader: 0,
			fields: [
				{ "name": "name", "type": "text", 'label': "Name", "id": "name" },
				{ "name": "phone", "type": "number", 'label': "Phone No.", "id": "phone no." },
				{ "name": "company", "type": "text", 'label': "Company (optional)", "id": "company" },
				{ "name": "address_line_1", "type": "text", 'label': "Address Line 1", "id": "address_line_1" },
				{ "name": "address_line_2", "type": "text", 'label': "Address Line 2", "id": "address_line_2" },
				{ "name": "state_id", "type": "select", 'label': "State", "id": "state" },
				{ "name": "city", "type": "text", 'label': "City / Town", "id": "city" },
				{ "name": "postal_code", "type": "number", 'label': "Postcode", "id": "postcode" },
				{ "name": "default_shipping_address", "type": "checkbox", 'label': "Default Address", "id": "default_shipping_address" },
			],
			formData: {
				"member_id": this.$route.query.member,
				"name": '',
				"phone": '',
				"company": '',
				"address_line_1": '',
				"address_line_2": '',
				"state_id": '',
				"city": '',
				"postal_code": '',
				"country_id": 1,
				"default_shipping_address": '',
			},
			errors: {},
			stateValOnLoad: '',
			componentKey: 0,
		};
	},
	async created() {
		this.action = this.$route.name == "Member Edit Address" ? "Edit" : "Add";
		this.items = [{ text: this.action + " Member Address" }];

		if (this.action === 'Edit') {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/member/address/${this.id}`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Member's Info error:" + error.message);
			});

			if (result.data['code'] === 'success') {
				Object.keys(result.data.data).forEach(element => {
					const { data } = result.data;
					if (this.formData[element] === '' && Object.hasOwn(data, element)) {
						this.formData[element] = data[element];
						if (element === "state_id") {
							this.stateValOnLoad = data[element] ?? "";
							this.componentKey += 1;
						}
					}
				});
				console.log('asd', this.formData)
			} else if (result.data['code'] === 'not_found') {
				alert('address fail:' + result.data.message);
			}
		}
	},
	methods: {
		async SubmitForm() {
			if (this.action === "Add") {
				const result = await axios.post(`${process.env.VUE_APP_APIURL}/member/add-address`, this.formData, {
					headers: authHeader(),
				}).catch(function (error) {
					alert("Something went wrong: " + error.message);
				});

				if (result.data.code === 'success') {
					// this.$refs.messageModal.showModal(result.data.message);
					router.push({ 'name': "Member View", params: { id: this.$route.query.member } });
				} else if (result.data['code'] === 'invalid_field') {
					this.errors = result.data['errors'];
				} else if (result.data['code'] === 'invalid_member') {
					alert(result.data.message);
				}
			} else {
				const result = await axios.put(`${process.env.VUE_APP_APIURL}/member/update-address/${this.id}`, this.formData, {
					headers: authHeader(),
				}).catch(function (error) {
					alert("Something went wrong: " + error.message);
				});

				if (result.data.code === 'success') {
					this.$refs.messageModal.showModal(result.data.message);
				} else if (result.data['code'] === 'invalid_field') {
					this.errors = result.data['errors'];
				} else if (result.data['code'] === 'invalid_member') {
					this.$refs.messageModal.showModal(result.data.message);
				} else if (result.data['code'] === 'invalid_default_shipping_address') {
					this.$refs.messageModal.showModal(result.data.message);
				}
			}
		},
		redirectPage() {
			router.push({ 'name': "Member View", params: { id: this.$route.query.member } })
		}
	},
};
</script>

<template>
	<Layout>
		<MessageModal ref="messageModal" />
		<PageHeader :title="title" :items="items" :key="updateHeader" />
		<div class="row mb-5">
			<div class="col-lg-12">
				<div class="card" style="border-radius: 15px;">
					<div class="card-body pt-3">
						<div class="row">
							<template v-for="field in fields">
								<div class="col-12 col-md-4 mb-2" :key="field.id" v-if="field.name !== 'phone' && (field.type === 'text' || field.type === 'number')">
									<InputGroup :type="field.type" :id="field.id" :displayName="field.label"
										v-model="formData[field.name]" :error="errors[field.name]" :ref="field.name" />
								</div>
								<div class="col-12 col-md-4 mb-2" :key="`select-${field.id}`" v-else-if="field.type === 'select'">
									<stateOption v-model="formData.state_id" :error="errors.state_id"
										:stateValue="stateValOnLoad" :key="componentKey"></stateOption>
								</div>
								<div class="col-12 col-md-4 mb-2" :key="`checkbox-${field.id}`" v-else-if="field.type === 'checkbox'">
									<input id="default_shipping_address" class="mr-2" :type="field.type" v-model="formData.default_shipping_address" value="1" />
									<label for="default_shipping_address">
										{{ field.label }}
									</label>
									<div class="alert alert-danger mt-3" v-if="errors.default_shipping_address">
										{{ errors.default_shipping_address[0] }}
									</div>
								</div>
								<div class="col-12 col-md-4 mb-2" :key="`phone-${field.id}`" v-else>
									<InputGroup type="text" id="phone" displayName="Phone No." v-model="formData.phone"
										:error="errors.phone" ref="phone" />
								</div>
							</template>
						</div>

						<button @click="SubmitForm" class="btn btn-orange mt-3">
							Submit
						</button>
						<button @click="redirectPage" class="btn btn-filter ml-2 mt-3">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
